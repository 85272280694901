<template>
  <tr>
    <wi-list-data-table-actions v-if="config.mode == 'select'" :config="config" :props="props"></wi-list-data-table-actions>
    <td v-else class="justify-left">
      <v-btn icon @click="hostDetailDialog = true">
        <v-icon color="primary">visibility</v-icon>
      </v-btn>
      <HostDetailDialog
        v-model="hostDetailDialog"
        :host="props.item"
        @refresh="refresh"
      ></HostDetailDialog>
    </td>
    <td class="justify-left">
      <span v-if="props.item.mac_address || props.item.serial">
        <span v-if="props.item.mac_address">{{ props.item.mac_address || '- - -' }}</span>
        <br v-if="props.item.mac_address && props.item.serial">
        <span v-if="props.item.serial">{{ props.item.serial || '- - -' }}</span>
      </span>
      <span v-else>
        <span v-if="props.item.identification">{{ props.item.identification.replace('@', '') }}</span>
      </span>
    </td>
    <td class="justify-left">
      <span>{{props.item.ip_address || '- - -'}}</span>
    </td>
    <td class="justify-left" style="cursor: pointer; font-size: 23px;">
      <HostSignalDialog :host="props.item">
        <span v-if="props.item.online">
          <strong :style="{color: props.item.signal_is_ok ? 'green' : 'orange'}">
            {{ props.item.signal || '- - -' }}
          </strong>
        </span>
        <span v-else style="font-size: 18px; color: red;">
          <strong v-if="props.item && props.item.complement && props.item.complement.status" style="font-size: 15px; color: orange;">
            <span v-if="props.item.complement.status == 1 || props.item.complement.status == 'undefined'" style="font-size: 18px; color: red;">
              Offline
            </span>
            <span v-else-if="props.item.complement.status == 2 || props.item.complement.status == 'LOS'">
              Link Loss
            </span>
            <span v-else-if="props.item.complement.status == 3 || props.item.complement.status == 'DyingGasp'">
              DiyngGasp
            </span>
            <span v-else style="font-size: 18px; color: red;">
              Offline
            </span>
          </strong>
          <span v-else style="font-size: 18px; color: red;">
            Offline
          </span>
        </span>
      </HostSignalDialog>
    </td>
    <td class="justify-left">
      <v-icon :color="props.item.technology.color" small>{{ props.item.technology.icon }}</v-icon>
      <strong> {{props.item.technology.name || '- - -'}}</strong>
    </td>
    <td class="justify-left">
      <strong>{{props.item.producer.name || '- - -'}}</strong><br>
      <span>{{props.item.producer_model.name || '- - -'}}</span>
    </td>
    <td class="justify-left">
      <strong v-if="props.item.active_devices[0]">{{ props.item.active_devices[0].nome_disp }}</strong>
      <strong v-else>- - -</strong><br>
      <span v-if="props.item.active_devices[0] && props.item.active_devices[0].network">{{ props.item.active_devices[0].network.name }}</span>
      <span v-else>- - -</span>
    </td>
    <td class="justify-left">
      <div v-if="props.item.active_childs && props.item.active_childs.length > 0">
        <div v-for="(child, childKey) in props.item.active_childs" :key="child.id">
          <strong v-if="!childKey">{{ child.type.name }}: {{ child.name }}</strong>
          <span v-else>{{ child.type.name }}: {{ child.name }}</span>
        </div>
      </div>
      <span v-else>- - -</span>
    </td>
    <td class="justify-left">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <span>{{ checkedAt.current }}</span>
          </div>
        </template>
        <span>{{ checkedAt.dateTime }}</span>
      </v-tooltip>
    </td>
  </tr>
</template>
<script>
  import { DateTimeCurrent } from '@/default/plugins/Utils/DateTimeCurrent'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import HostSignalDialog from '@/modules/network/pages/Host/HostSignalDialog'
  import HostDetailDialog from '@/modules/network/pages/Host/HostDetail/HostDetailDialog'
  export default {
    name: 'Hosts',
    data: () => ({
      hostDetailDialog: false,
      pingDialog: {
        active: false,
        host: null,
        server: null
      }
    }),
    computed: {
      checkedAt () {
        return DateTimeCurrent.format(this.props.item.checked_at)
      }
    },
    methods: {
      pingDialogAction: function (active = false, server = null, host = null) {
        this.pingDialog.host = host
        this.pingDialog.server = server
        this.pingDialog.active = active
      },
      pingIsAvaiable: function () {
        return false
      },
      refresh: function () {
        this.config.refresh()
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions,
      HostSignalDialog,
      HostDetailDialog
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>